import {mapActions, mapGetters} from "vuex";
import MintSuccessPopup from '@/components/popups/mint-success-popup.vue'
import ApprovePopup from '@/components/popups/approve-popup.vue'
import ApiMint from '@/service/ApiMint';

export default {
  components: {
    MintSuccessPopup,
    ApprovePopup,
  },
  data() {
    return {
      isMinting: false,
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
  },
  methods: {
    ...mapActions('contract', ['allowance', 'approve', 'safeMint', 'getBalance']),

    async onNftMintClick(character) {
      if (!this.isLoggedIn) {
        this.$eventBus.$emit('errorPopup', '', 'To MINT the character Connect to the Metamask through the Metafighter site.');

        return;
      }
      console.log('character', { ...character });

      this.isMinting = true

      const allowance = await this.allowance();

      if (allowance === 'error') {
        return;
      }

      if (this.fromWei(allowance) < Number(character.price)) {
        // лимит на снятия средств меньше чем стоимость (показываем попап с описанием)
        this.$refs.approvePopup.open(character.price, character);
      } else {
        // можно минтить
        await this.mintCharacter(character);
      }

      this.isMinting = false;
    },
    async mintCharacter(character) {
      this.isMinting = true
      try {
        const vrsData = await ApiMint.getVrs(character.character_id);

        const {character_id, salt, price, vrs} = vrsData.data;

        const result = await this.safeMint({
          character_id,
          salt,
          price,
          vrs
        });

        await this.getBalance();

        this.$refs.mintSuccessPopup.open(result.transactionHash)

        console.log('result', result)
      } catch (error) {
        this.$eventBus.$emit('errorPopup', '', error.message || 'unexpected error');
      }
      this.isMinting = false;
    },
  },
}
